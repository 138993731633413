import * as actionTypes from './actionTypes'

export const actionHubUpdateTaskDetails = (actionSetId, taskId, data) => ({
  type: actionTypes.ACTION_HUB_UPDATE_TASK_DETAILS,
  actionSetId,
  taskId,
  data,
})

export const actionHubRemoveTask = taskId => ({
  type: actionTypes.ACTION_HUB_REMOVE_TASK,
  taskId,
})

export const actionHubAddingTask = isAddingTask => ({
  type: actionTypes.ACTION_HUB_ADDING_TASK,
  isAddingTask,
})

export const actionHubUpdatingTaskDetails = isUpdatingDetails => ({
  type: actionTypes.ACTION_HUB_UPDATING_TASK_DETAILS,
  isUpdatingDetails,
})

export const actionHubFetchSelectedTasks = isFetching => ({
  type: actionTypes.ACTION_HUB_FETCH_SELECTED_TASKS,
  isFetching,
})

export const actionHubFetchedTagDetails = (tagId, details) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TAG_DETAILS,
  tagId,
  details,
})

export const actionHubImportingActions = () => ({
  type: actionTypes.ACTION_HUB_IMPORTING_ACTIONS,
})
export const actionHubImportedActions = () => ({
  type: actionTypes.ACTION_HUB_IMPORTED_ACTIONS,
})
export const actionHubErroredImportingActions = () => ({
  type: actionTypes.ACTION_HUB_ERRORED_IMPORTING_ACTIONS,
})

export const actionHubFetchingTasks = actionSetId => ({
  type: actionTypes.ACTION_HUB_FETCHING_TASKS,
  actionSetId,
})

export const actionHubFetchedTasks = (actionSetId, tasks, filterString, filterStatus) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TASKS,
  actionSetId,
  tasks,
  filterString,
  filterStatus,
})

export const actionHubUpdatedTagTaskAssociation = (dataIds, tagId) => ({
  type: actionTypes.ACTION_HUB_UPDATED_TASKS_ADD_TAG,
  dataIds,
  tagId,
})

export const actionHubFetchingAction = actionSetId => ({
  type: actionTypes.ACTION_HUB_FETCHING_ACTION,
  actionSetId,
})

export const actionHubFetchingTags = actionSetId => ({
  type: actionTypes.ACTION_HUB_FETCHING_TAGS,
  actionSetId,
})

export const actionHubFetchedAction = (actionSetId, data) => ({
  type: actionTypes.ACTION_HUB_FETCHED_ACTION,
  ...data,
})

export const actionHubFetchedTags = (actionSetId, tags) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TAGS,
  tags,
})

export const fetchedUpdatedTasksTokens = data => ({
  type: actionTypes.ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS,
  data,
})

export const actionHubFetchingTaskData = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_FETCHING_TASK_DATA,
  actionSetId,
  taskId,
})

export const actionHubFetchedTaskData = (actionSetId, taskId, data) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TASK_DATA,
  actionSetId,
  taskId,
  data,
})

export const actionHubFetchedTaskDataNoData = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TASK_DATA_NO_DATA,
  actionSetId,
  taskId,
})

export const actionHubFetchedTaskMeta = (actionSetId, taskId, data) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TASK_META,
  actionSetId,
  taskId,
  data,
})

export const actionHubFetchingDataDetail = (actionSetId, taskId, dataId) => ({
  type: actionTypes.ACTION_HUB_FETCHING_DATA_DETAIL,
  actionSetId,
  taskId,
  dataId,
})

export const actionHubFetchedDataDetail = (actionSetId, taskId, dataId, data) => ({
  type: actionTypes.ACTION_HUB_FETCHED_DATA_DETAIL,
  actionSetId,
  taskId,
  dataId,
  data,
})

export const actionHubUpdateTaskLabelValue = (actionSetId, taskId, labelId, value) => ({
  type: actionTypes.ACTION_HUB_UPDATE_TASK_LABEL_VALUE,
  actionSetId,
  taskId,
  labelId,
  value,
})

export const actionHubUpdateTagLabelValue = (actionSetId, tagId, labelId, value) => ({
  type: actionTypes.ACTION_HUB_UPDATE_TAG_LABEL_VALUE,
  actionSetId,
  tagId,
  labelId,
  value,
})

export const actionHubUpdateTagDescription = (actionSetId, tagId, description, narrative, name) => ({
  type: actionTypes.ACTION_HUB_UPDATE_TAG_DATA,
  actionSetId,
  tagId,
  description,
  narrative,
  name,
})

export const actionHubFetchingTagTasks = (actionSetId, tagId) => ({
  type: actionTypes.ACTION_HUB_FETCHING_TAG_TASKS,
  actionSetId,
  tagId,
})

export const actionHubFetchedTagTasks = (actionSetId, tagId, data) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TAG_TASKS,
  actionSetId,
  tagId,
  data,
})

export const submittingTaskLabels = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_TASK_LABELS,
  actionSetId,
  taskId,
})

export const submittedTaskLabels = (actionSetId, taskId, jobId, payload) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_LABELS,
  actionSetId,
  taskId,
  jobId,
  payload,
})

export const submittingTagLabels = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_TAG_LABELS,
  actionSetId,
  taskId,
})

export const submittingTaskLabelsFailed = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED,
  actionSetId,
  taskId,
})

export const submittedTagLabels = (actionSetId, tagId, jobId, payload) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TAG_LABELS,
  actionSetId,
  tagId,
  jobId,
  payload,
})

export const validateTaskLabel = (actionSetId, taskId, labelId, error = null) => ({
  type: actionTypes.ACTION_HUB_VALIDATE_TASK_LABEL,
  actionSetId,
  taskId,
  labelId,
  error,
})

export const validateTagLabel = (actionSetId, tagId, labelId, error = null) => ({
  type: actionTypes.ACTION_HUB_VALIDATE_TAG_LABEL,
  actionSetId,
  tagId,
  labelId,
  error,
})

export const submittedTaskAssignee = (actionSetId, taskIds, userId, assigneeType) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_ASSIGNEE,
  actionSetId,
  taskIds,
  userId,
  assigneeType,
})

export const tagCreated = (tag, taskIds) => ({
  type: actionTypes.ACTION_HUB_TAG_CREATED,
  tag,
  taskIds,
})

export const tagCommentsUpdated = (tagId, comments) => ({
  type: actionTypes.ACTION_HUB_TAG_COMMENTS_UPDATED,
  tagId,
  comments,
})

export const submittedTaskStatus = (actionSetId, taskIds, statusId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_STATUS,
  actionSetId,
  taskIds,
  statusId,
})

export const submittedTaskActionState = (actionSetId, taskIds, taskActionId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE,
  actionSetId,
  taskIds,
  taskActionId,
})

export const submittedTaskPriority = (actionSetId, taskIds, priorityId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_PRIORITY,
  actionSetId,
  taskIds,
  priorityId,
})

export const actionHubUpdateDataLabelValue = (actionSetId, dataId, labelId, value) => ({
  type: actionTypes.ACTION_HUB_UPDATE_DATA_LABEL_VALUE,
  actionSetId,
  dataId,
  labelId,
  value,
})

export const validateDataLabel = (actionSetId, dataId, labelId, error = null) => ({
  type: actionTypes.ACTION_HUB_VALIDATE_DATA_LABEL,
  actionSetId,
  dataId,
  labelId,
  error,
})

export const submittingDataLabels = (actionSetId, taskId, dataId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_DATA_LABELS,
  actionSetId,
  taskId,
  dataId,
})

export const submittedDataLabels = (actionSetId, taskId, dataId, payload) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_DATA_LABELS,
  actionSetId,
  taskId,
  dataId,
  payload,
})

export const submittingDataComment = (actionSetId, taskId, dataId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_DATA_COMMENT,
  actionSetId,
  taskId,
  dataId,
})

export const submittedDataComment = (actionSetId, taskId, dataId, comment) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_DATA_COMMENT,
  actionSetId,
  taskId,
  dataId,
  comment,
})

export const submittingTaskComment = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_TASK_COMMENT,
  actionSetId,
  taskId,
})

export const submittedTaskComment = (actionSetId, taskId, comment) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_COMMENT,
  actionSetId,
  taskId,
  comment,
})

export const actionHubUpdateSelectedTasks = (actionSetId, taskIds, data) => ({
  type: actionTypes.ACTION_HUB_UPDATE_SELECTED_TASKS,
  actionSetId,
  taskIds,
  data,
})

export const selectedTabUpdate = selectedTab => ({
  type: actionTypes.ACTION_HUB_SELECTED_TAB_UPDATE,
  selectedTab,
})

export const updateTaskListScrollLocation = scrollLocation => ({
  type: actionTypes.ACTION_HUB_TASKLIST_SCROLL_LOCATION,
  scrollLocation,
})

export const updateTaskListSearchQuery = searchQuery => ({
  type: actionTypes.ACTION_HUB_TASKLIST_SEARCH_QUERY,
  searchQuery,
})

export const updateIsCommentRequired = (taskId, isCommentRequired) => ({
  type: actionTypes.ACTION_HUB_UPDATE_IS_COMMENT_REQUIRED,
  taskId,
  isCommentRequired,
})

export const updateIsCommentReceived = (taskId, isCommentReceived) => ({
  type: actionTypes.ACTION_HUB_UPDATE_IS_COMMENT_RECEIVED,
  taskId,
  isCommentReceived,
})

export const updateSignOffDetails = (taskId, signOffName, signOffDate) => ({
  type: actionTypes.ACTION_HUB_UPDATE_SIGN_OFF_DETAILS,
  taskId,
  signOffName,
  signOffDate,
})

export const submittingTaskCommentFailed = (actionSetId, taskId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTING_TASK_COMMENT_FAILED,
  actionSetId,
  taskId,
})

export const submittedTaskCommentStatus = (actionSetId, taskIds, commentStatusId) => ({
  type: actionTypes.ACTION_HUB_SUBMITTED_TASK_COMMENT_STATUS,
  actionSetId,
  taskIds,
  commentStatusId,
})

export const actionHubFetchedTaskComments = (actionSetId, taskId, comments) => ({
  type: actionTypes.ACTION_HUB_FETCHED_TASK_COMMENTS,
  actionSetId,
  taskId,
  comments,
})

export const resetLabels = taskId => ({
  type: actionTypes.RESET_LABELS,
  payload: { taskId },
})

export const actionHubFetchingCommentAuthors = () => ({
  type: actionTypes.ACTION_HUB_FETCHING_COMMENT_AUTHORS,
})

export const actionHubFetchedCommentAuthor = (userId, authorData) => ({
  type: actionTypes.ACTION_HUB_FETCHED_COMMENT_AUTHOR,
  userId,
  authorData,
})

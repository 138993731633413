import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  IconClose, IconSuccessOutline,
  IconWarningOutline, IconErrorOutline,
} from 'icons'
import styles from './Snackbar.scss'

const Snackbar = ({
  active,
  className,
  label,
  onClick,
  onTimeout,
  timeout,
  type,
}) => {
  useEffect(() => {
    if (active && onTimeout) {
      const timer = setTimeout(onTimeout, timeout)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line no-undefined
    return undefined
  }, [active, onTimeout, timeout])

  if (!active) { return null }

  // eslint-disable-next-line no-shadow
  const getIcon = (type) => {
    switch (type) {
      case 'error':
        return <IconErrorOutline height="20" width="20" />
      case 'warning':
        return <IconWarningOutline height="22" width="22" />
      case 'accept':
        return <IconSuccessOutline height="20" width="20" />
      default:
        return <IconSuccessOutline height="20" width="20" />
    }
  }

  return (
    <div className={classnames(styles.snackbar, className)}>
      <div className={classnames(styles.icon, styles[type])}>
        {getIcon(type)}
      </div>
      <span className={styles.label}>{label}</span>
      <button
        className={styles.button}
        onClick={onClick}
        type="button"
      >
        <IconClose size={14} />
      </button>
    </div>
  )
}

Snackbar.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onTimeout: PropTypes.func,
  timeout: PropTypes.number,
  type: PropTypes.oneOf(['accept', 'warning', 'error']),
}

Snackbar.defaultProps = {
  active: false,
  className: '',
  label: '',
  onClick: () => {},
  onTimeout: () => {},
  timeout: 2800,
  type: 'accept',
}

export default Snackbar

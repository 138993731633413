import React from 'react'
import PropTypes, { object } from 'prop-types'
import { userPropType, priorityPropType } from 'utils/propTypes'
import { sortByFunction } from 'utils/arrays'
import Priority from 'actionHub/components/Priority'
import User from 'components/User'
import { formatDateTimeFromStringShort } from 'utils/dates'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Tooltip from '@material-ui/core/Tooltip'
import { DropdownMenu } from '@deloitte/gel-library'

import classNames from 'classnames'
import { useSelector } from 'react-redux'
import ActionState from 'actionHub/components/ActionState'
import styles from './DetailedViewHeader.scss'

const propTypes = {
  actionStates: PropTypes.arrayOf(object),
  allowTags: PropTypes.bool,
  clients: PropTypes.arrayOf(userPropType).isRequired,
  deleteTagAssociation: PropTypes.func,
  disabled: PropTypes.bool,
  headerType: PropTypes.oneOf(['caseView', 'tickmarkView']).isRequired,
  hideDropdowns: PropTypes.bool,
  members: PropTypes.arrayOf(userPropType),
  priorities: PropTypes.arrayOf(priorityPropType),
  saveTagName: PropTypes.func,
  setTaskAssignee: PropTypes.func,
  setTaskPriority: PropTypes.func,
  setTasksActionState: PropTypes.func,
  setTaskTag: PropTypes.func,
  tagName: PropTypes.string,
  tagUpdatedBy: PropTypes.string,
  task: PropTypes.shape({
    _id: PropTypes.number,
    _isLabelsModified: PropTypes.bool,
    assignedTo: PropTypes.string,
    clientTeamId: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.object),
    priorityId: PropTypes.number,
    reviewerId: PropTypes.string,
    statusId: PropTypes.number,
    tagId: PropTypes.string,
    taskActionId: PropTypes.number,
    taskColumns: PropTypes.arrayOf(PropTypes.object),
    taskData: PropTypes.object,
    updatedBy: PropTypes.string,
    updatedDate: PropTypes.string,
  }),
  taskTitle: PropTypes.string,
  timestamp: PropTypes.string,
  updatedUser: PropTypes.object,
  updateTagName: PropTypes.func,
}

const defaultProps = {
  allowTags: false,
  actionStates: [],
  deleteTagAssociation: null,
  disabled: false,
  hideDropdowns: false,
  setTasksActionState: null,
  setTaskPriority: null,
  setTaskAssignee: null,
  members: [],
  task: null,
  taskTitle: null,
  priorities: [],
  tagName: '',
  tagUpdatedBy: '',
  updateTagName: null,
  timestamp: null,
  saveTagName: null,
  setTaskTag: null,
  updatedUser: null,
}

function DetailedViewHeader({
  actionStates, saveTagName, deleteTagAssociation, setTaskTag, task, setTaskPriority, setTasksActionState, setTaskAssignee, taskTitle, tagName,
  priorities, members, timestamp, headerType, tagUpdatedBy, updateTagName, disabled, allowTags, updatedUser, clients, hideDropdowns,
}) {
  const closeView = () => {
    window.history.back()
  }

  const { tagsList } = useSelector(state => state.actionHub.tags)
  const { allowClientComments = false } = useSelector(state => state.actionHub.taskLevel)

  const priorityOptions = priorities
    .concat()
    .sort(sortByFunction(p => p.value, true))
    .map(p => ({
      key: p.id,
      label: <Priority showDescription priority={p} />,
      labelTitle: <Priority priority={p} />,
      onClick: () => setTaskPriority(p.id),
    }))

  const actions = actionStates
    .map(as => ({
      key: `${as.id}`,
      label: <ActionState actionState={as} />,
      onClick: () => {
        const taskIds = [task._id]
        setTasksActionState(taskIds, as.id)
      },
    }))

  const unassignedUser = {
    userDetails: {
      firstName: 'Unassigned',
      surname: '',
      email: '',
    },
  }

  const userList = (users, assigneeType) => ([
    ...users.map(u => ({
      key: u.id,
      label: <User showEmail user={u} />,
      labelTitle: <User user={u} />,
      onClick: () => setTaskAssignee(u.id, assigneeType),
    })),
    {
      key: '',
      label: <User user={unassignedUser} />,
      onClick: () => setTaskAssignee(null, assigneeType),
    },
  ])

  const tagNamesArray = tagsList.concat().sort(sortByFunction(p => p.name))
    .map(p => ({
      key: p.id,
      label: <div className={styles.activeTagName}>{p.name}</div>,
      onClick: () => {
        const taskIds = []
        taskIds.push(task._id)
        setTaskTag(taskIds, p.name, p.description, p.id, p.narrative)
      },
    }))

  const unTagged = {
    key: '',
    label: 'Remove tag',
    onClick: () => {
      const taskIds = []
      taskIds.push(task._id)
      deleteTagAssociation(taskIds)
    },
  }

  tagNamesArray.push(unTagged)

  const editName = (value) => {
    updateTagName(value)
  }

  const getTagName = () => {
    const currentTagName = tagsList.find(tag => tag.id === task.tagId)?.name
    return currentTagName ? (
      <div className={styles.activeTagName}>
        {currentTagName}
      </div>
    ) : (
      <div className={styles.unassignedTagName}>
        Assign tag
      </div>
    )
  }

  const getPriority = () => {
    const priority = priorityOptions.find(p => p.key === task.priorityId)
    return priority?.labelTitle
  }

  const getActionState = () => {
    return <ActionState actionState={actionStates.find(s => s.id === task.taskActionId)} />
  }

  const getAssignee = (users, key, label) => {
    const assignee = userList(users, key).find(u => u.key === task[key])
    return assignee ? assignee.labelTitle : `No ${label}`
  }

  const assigneeList = [
    {
      users: members,
      key: 'assignedTo',
      label: 'Practitioner',
    },
    ...(allowClientComments ? [
      {
        users: clients,
        key: 'clientTeamId',
        label: 'Client',
      },
      {
        users: members,
        key: 'reviewerId',
        label: 'Reviewer',
      },
    ] : []),
  ]

  return (
    <div>
      {headerType === 'caseView' && (
        <div className={classNames(styles.container)}>
          <div className={classNames(styles.taskDetails)}>
            <div className={styles.backButton}>
              <ArrowBackIcon onClick={() => closeView()} />
            </div>
            <Tooltip arrow interactive title={taskTitle}>
              <div className={classNames(styles.taskTitle)} id="taskTitle">
                {taskTitle}
              </div>
            </Tooltip>
          </div>
          {!hideDropdowns && (
            <div className={classNames(styles.container, styles.taskMeta)}>
              {allowTags && (
                <div className={styles.dropdown}>
                  <DropdownMenu disabled={disabled} displayText={getTagName()} options={tagNamesArray} />
                </div>
              )}
              <div className={styles.dropdown}>
                <DropdownMenu disabled={disabled} displayText={getPriority()} options={priorityOptions} />
              </div>
              <div className={styles.dropdown}>
                <DropdownMenu disabled={disabled} displayText={getActionState()} options={actions} />
              </div>
              {assigneeList.map(assignee => (
                <div className={styles.dropdown}>
                  <DropdownMenu
                    disabled={disabled}
                    displayText={getAssignee(assignee.users, assignee.key, assignee.label)}
                    options={userList(assignee.users, assignee.key)}
                  />
                </div>
              ))}
              {updatedUser && (
                <div className={styles.lastUpdated}>
                  {`Last updated by ${updatedUser.userDetails.firstName} ${updatedUser.userDetails.surname} ${formatDateTimeFromStringShort(timestamp)}`}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {headerType === 'tickmarkView'
      && (
      <div className={classNames(styles.flexContainer, styles.flexSpace)}>
        <div className={styles.tagSubDiv}>
          <input
            className={styles.tagName}
            disabled={disabled}
            onBlur={e => saveTagName(e.target.value)}
            onChange={e => editName(e.target.value)}
            size={tagName.length ?? 1}
            value={tagName}
          />

        </div>
        <div className={classNames(styles.flexContainer, styles.tagSubDiv, styles.flexEnd)}>
          <div className={styles.details}>{`Last updated by ${tagUpdatedBy} ${formatDateTimeFromStringShort(timestamp)}`}</div>
        </div>
      </div>
      )}
    </div>
  )
}

DetailedViewHeader.propTypes = propTypes
DetailedViewHeader.defaultProps = defaultProps

export default DetailedViewHeader

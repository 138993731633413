import {
  downloadSupportingFile,
  uploadFile,
  commitSupportingFile,
  deleteSupportingFile,
  uploadSupportingFile,
} from 'utils/api/file'
import { deleteFile } from 'utils/api/fileGroup'
import { notify } from './app'
import {
  endUploadingFiles,
  finishedSpinningVisualisation,
  startedSpinningVisualisation,
  startedUploadingFiles,
  updatedDataPrepReqStatus,
  updateFileGroupFileUploadStatus,
  uploadFileToFileGroup,
  updateSupportingFilesStatus,
} from './job'

export function uploadFileGroupFiles(formsData) {
  return (dispatch, getState) => {
    const {
      job: { fileGroups, jobId },
    } = getState()

    dispatch(startedUploadingFiles())
    dispatch(startedSpinningVisualisation())

    Promise.all(
      formsData.map((formData) => {
        const fileGroupInConcern = fileGroups.find(
          fg => fg.name === formData.get('tableCode'),
        )
        const fileName = formData.get('qqfilename')
        const fileSize = fileGroupInConcern?.files.find(
          f => f?.name === fileName,
        )?.size

        return uploadFile(formData, jobId)
          .then(() => {
            const targetFileGroup = fileGroups.find(
              fg => fg.name === formData.get('tableCode'),
            )
            dispatch(
              uploadFileToFileGroup(targetFileGroup, {
                name: fileName,
                size: fileSize,
              }),
            )
            dispatch(
              updateFileGroupFileUploadStatus({
                file: formData.get('file'),
                fileGroup: fileGroupInConcern,
                status: 'complete',
              }),
            )
            dispatch(updatedDataPrepReqStatus('Modified'))
            dispatch(notify('Successfully uploaded file ', formData.get('file').name))
          })
          .catch((error) => {
            console.error('Upload error:', error)
            dispatch(notify('Failed to upload file', 'error'))
            dispatch(
              updateFileGroupFileUploadStatus({
                file: formData.get('file'),
                fileGroup: fileGroupInConcern,
                status: 'failed',
              }),
            )
          })
      }),
    )
      .catch((error) => {
        console.error('Promise.all error:', error)
      })
      .finally(() => {
        dispatch(endUploadingFiles())
        setTimeout(() => {
          dispatch(finishedSpinningVisualisation())
        }, 2000)
      })
  }
}

export function uploadSupportingFiles(formsData, isExternal, jobId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const {
        job: { supportingFileStatus, myaId },
      } = getState()

      const effectiveJobId = isExternal ? jobId : getState().job.jobId

      dispatch(startedUploadingFiles())
      dispatch(startedSpinningVisualisation())

      const uploadPromises = formsData.map((formData) => {
        const fileName = formData.get('file').name
        return uploadSupportingFile(formData, myaId, isExternal)
          .then(() => {
            const commitData = {
              name: fileName,
              tablename: formData.get('tableCode'),
              size: formData.get('file').size,
            }

            return commitSupportingFile(commitData, effectiveJobId, isExternal)
              .then(() => {
                dispatch(notify(`Successfully uploaded file: ${fileName}`))
                return { name: fileName, status: 'complete' }
              })
              .catch((error) => {
                console.error('Commit error:', error)
                dispatch(notify(`Failed to commit file: ${fileName}`, 'error'))
                return { name: fileName, status: 'failed' }
              })
          })
          .catch((error) => {
            console.error('Upload error:', error)
            dispatch(notify(`Failed to upload file: ${fileName}`, 'error'))
            return { name: fileName, status: 'failed' }
          })
      })

      Promise.all(uploadPromises)
        .then((results) => {
          const updatedFilesWithStatus = supportingFileStatus.map((fileStatus) => {
            const result = results.find(r => r.name === fileStatus.name)
            return result ? { ...fileStatus, status: result.status } : fileStatus
          })
          dispatch(updateSupportingFilesStatus(updatedFilesWithStatus))
          resolve()
        })
        .catch((error) => {
          console.error('Error in Promise.all:', error)
          reject(error)
        })
        .finally(() => {
          dispatch(endUploadingFiles())
          setTimeout(() => {
            dispatch(finishedSpinningVisualisation())
          }, 2000)
        })
    })
  }
}

export function removeFile(fileGroupId, { name }, callback) {
  return (dispatch) => {
    deleteFile(fileGroupId, { name })
      .then(() => {
        callback()
        dispatch(updatedDataPrepReqStatus('Modified'))
      })
      .catch((error) => {
        console.error('Delete error:', error)
        dispatch(notify('Failed to delete the file', 'error'))
      })
  }
}

export function removeSupportingFile(formData, jobID, isExternal) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      deleteSupportingFile(formData, jobID, isExternal)
        .then(() => {
          dispatch(notify(`File: ${formData.name} deleted.`))
          resolve()
        })
        .catch((error) => {
          console.error('Delete error:', error)
          dispatch(notify(`Failed to delete ${formData.name}.`, 'error'))
          reject(error)
        })
    })
  }
}

export function downloadAttachedFile(formData, jobId, isExternal) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      downloadSupportingFile(formData, jobId, isExternal)
        .then((response) => {
          const { headers, data } = response
          const contentDisposition = headers['content-disposition']
          let fileName = formData.name

          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(
              /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            )
            if (fileNameMatch && fileNameMatch[1]) {
              fileName = fileNameMatch[1].replace(/['"]/g, '')
              fileName = decodeURIComponent(fileName.replace(/^UTF-8''/, ''))
            }
          }

          const blob = new Blob([data], { type: headers['content-type'] })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)

          dispatch(notify(`File: ${fileName} download started.`))
          resolve()
        })
        .catch((error) => {
          console.error('Download error:', error)
          dispatch(notify(`Failed to download ${formData.name}.`, 'error'))
          reject(error)
        })
    })
  }
}

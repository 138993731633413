import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useEffect, useMemo, useCallback } from 'react'
import {
  putLabels,
  putTasksAssignee,
  putTasksStatus,
  putTasksPriority,
  getTaskMeta,
  getTaskData,
  postTaskComment,
  deleteTaskComment,
  putLabelsBulkUpdate,
  putTaskDetails,
  getTaskDetailsOnly,
  putTasksUpdate,
  putTasksCommentStatus,
  getTaskComments,
} from 'actionHub/utils/actionHubApi'
import {
  actionHubFetchingTaskData,
  actionHubFetchedTaskData,
  actionHubUpdateTaskLabelValue,
  submittingTaskLabels,
  submittedTaskLabels,
  validateTaskLabel,
  submittedTaskAssignee,
  submittedTaskStatus,
  submittedTaskPriority,
  actionHubFetchedTaskMeta,
  actionHubFetchedTaskDataNoData,
  submittingTaskComment,
  submittedTaskComment,
  submittingTaskLabelsFailed,
  actionHubUpdateSelectedTasks,
  actionHubFetchSelectedTasks,
  submittedTaskActionState,
  actionHubUpdateTaskDetails,
  actionHubUpdatingTaskDetails,
  updateIsCommentRequired,
  updateIsCommentReceived,
  submittingTaskCommentFailed,
  submittedTaskCommentStatus,
  actionHubFetchedTaskComments,
  actionHubRemoveTask,
} from 'actionHub/redux/actions'
import useApp from 'hooks/useApp'
import { modalHide } from 'actions/modals'
import { updateJobDownload } from 'actions/job'
import { downloadStatuses } from 'utils/business/jobs'
import { putUpdateDownloadStatus, getUserById } from 'utils/api/job'
import { notify } from 'actions/app'
import useAction from './useAction'

import {
  actionHubFetchingCommentAuthors,
  actionHubFetchedCommentAuthor,
} from '../redux/actions'

export default function useTask(actionSetId, taskId, taskIds) {
  const { updateGlobalFilters } = useAction(actionSetId)
  const dispatch = useDispatch()
  const { showNotification } = useApp()

  const jobId = useSelector(state => state.actionHub.actionSet?.jobId)
  const task = useSelector(state => state.actionHub.tasks[taskId])
  const tasks = useSelector(state => (taskIds ? taskIds.map(id => state.actionHub.tasks[id]) : state.actionHub.tasks[taskId]))
  const allTasks = useSelector(state => state.actionHub.tasks)
  const taskData = useSelector(state => (task && task.dataIds ? task.dataIds.map(id => state.actionHub.data[id].data) : []))
  const { isChildDataLevel } = useSelector(state => state.actionHub.dataLevel)
  const {
    displayColumns: columns, labels, idColumns, meta, allowAttachments,
  } = useSelector(state => state.actionHub.dataLevel)
  const {
    idColumns: taskIdColumns,
    displayColumns: taskDisplayColumns,
    displayDetailColumns,
    allowComments,
  } = useSelector(state => state.actionHub.taskLevel)
  const selectedTasks = useSelector(state => state.actionHub.selectedTasks)
  const currentJob = useSelector(state => state.job)
  const isUserExternal = useSelector(state => state.app.user.isExternal)

  const mergedColumns = [
    ...columns.map(x => ({ ...x, idColumn: idColumns.includes(x.name) })),
    ...(labels || []).map(l => ({ name: `_${l.name}`, displayName: l.displayName, dataType: l.labelType })),
  ]

  const needsLoading = useMemo(() => {
    return !task || !(task._isFetched || task._isFetching)
  }, [task])

  const taskTitle = needsLoading || !task.taskData ? '' : taskDisplayColumns
    .filter(x => taskIdColumns.includes(x.name))
    .map(c => `${c.displayName} ${task.taskData[c.name]}`)
    .join(', ')

  const loadTaskData = useCallback((id) => {
    dispatch(actionHubFetchingTaskData(actionSetId, id))

    getTaskMeta(actionSetId, id)
      .then(({ data: responseData }) => {
        dispatch(actionHubFetchedTaskMeta(actionSetId, id, responseData))

        if (allowComments) {
          return getTaskComments(actionSetId, id)
            .then(({ data: comments }) => {
              const uniqueUserIds = [...new Set(comments.map(c => c.userId))]

              dispatch(actionHubFetchingCommentAuthors())

              Promise.all(
                uniqueUserIds.map(userId => getUserById(userId)
                  .then(({ data }) => {
                    dispatch(actionHubFetchedCommentAuthor(userId, data))
                  })
                  .catch((e) => {
                    showNotification('Failed to fetch comment author', e, 'error')
                    return null
                  })),
              )

              dispatch(actionHubFetchedTaskComments(actionSetId, id, comments))
            })
        }
        return Promise.resolve()
      })
      .then(() => {
        if (isChildDataLevel) {
          return getTaskData(actionSetId, id, 0)
        }
        dispatch(actionHubFetchedTaskDataNoData(actionSetId, id))
        return Promise.resolve()
      })
      .then((taskDataResponse) => {
        if (taskDataResponse && taskDataResponse.data) {
          dispatch(actionHubFetchedTaskData(actionSetId, id, taskDataResponse.data))
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          dispatch(actionHubFetchedTaskDataNoData(actionSetId, id))
        } else {
          showNotification('Failed to load task data, please refresh the page and contact support if issues persist.', e, 'error')
          dispatch(actionHubFetchedTaskDataNoData(actionSetId, id))
          dispatch(push(`/action/${actionSetId}`))
        }
      })
  }, [actionSetId, allowComments, dispatch, isChildDataLevel, showNotification])

  useEffect(() => {
    if (Array.isArray(taskIds) && taskIds.length) {
      taskIds.forEach((id) => {
        loadTaskData(id)
      })
    } else if (needsLoading) {
      loadTaskData(taskId)
    }
  }, [taskIds, needsLoading, loadTaskData, taskId])

  const fetchNextPage = () => {
    if (task.pagination.hasNext && !task._isFetching) {
      dispatch(actionHubFetchingTaskData(actionSetId))
      getTaskData(actionSetId, taskId, task.pagination.page + 1)
        .then(({ data: responseData }) => {
          dispatch(actionHubFetchedTaskData(
            actionSetId,
            taskId,
            responseData,
          ))
        })
        .catch((e) => {
          showNotification('Could not load more data, please refresh the page and contact support if issues persist.', e, 'error')
        })
    }
  }

  const goToTaskList = () => {
    dispatch(push(`/action/${actionSetId}`))
  }

  const setLabelValue = (labelId, value) => {
    if (taskIds && tasks) {
      taskIds.forEach((id) => {
        dispatch(actionHubUpdateTaskLabelValue(actionSetId, id, labelId, value))
      })
    } else {
      dispatch(actionHubUpdateTaskLabelValue(actionSetId, taskId, labelId, value))
    }
  }

  const validateLabel = (labelId, error = null) => {
    if (taskIds && tasks) {
      taskIds.forEach((id) => {
        dispatch(validateTaskLabel(actionSetId, id, labelId, error))
      })
    } else {
      dispatch(validateTaskLabel(actionSetId, taskId, labelId, error))
    }
  }

  const afterLabelsUpdated = (data, isBulkUpdate) => {
    const notificationText = isBulkUpdate ? 'Bulk updates saved' : 'Review findings saved'
    dispatch(submittedTaskLabels(actionSetId, taskId, jobId, {}))
    if (isBulkUpdate) {
      dispatch(actionHubFetchSelectedTasks(false))
    }
    showNotification(notificationText)
    dispatch(submittedTaskStatus(actionSetId, [taskId], data.statusId))
    updateGlobalFilters()
  }

  const labelUpdateErrorResponse = (e) => {
    if (e.response && e.response.status === 400) {
      const errors = e.response.data.result.info.message
        .split(';')
        .map((error) => {
          const components = error.split('|')
          return {
            labelId: components[0],
            error: components[1],
          }
        })

      errors.forEach((error) => {
        dispatch(validateTaskLabel(actionSetId, taskId, error.labelId, error.error))
      })

      dispatch(submittingTaskLabelsFailed(actionSetId, taskId))
      showNotification('Some values were invalid, please review the form and try again.', e, 'error')
    } else {
      showNotification('Could not save changes, please refresh the page and contact support if issues persist.', e, 'error')
    }
  }

  const updateDownloadStatus = () => {
    const currentJobDownloadName = currentJob.downloads[0].name
    putUpdateDownloadStatus(jobId, currentJobDownloadName, downloadStatuses.notStarted, isUserExternal)
      .then(() => {
        dispatch(updateJobDownload(jobId, currentJobDownloadName, { status: downloadStatuses.notStarted }))
      })
      .catch(() => {
        dispatch(notify('Failed to update download status; please contact support', 'error'))
      })
  }

  const updateTaskDetails = (updatedData) => {
    dispatch(actionHubUpdatingTaskDetails(true))
    putTaskDetails(actionSetId, taskId, updatedData)
      .then(({ data }) => {
        dispatch(actionHubUpdateTaskDetails(actionSetId, taskId, data))
        dispatch(actionHubUpdatingTaskDetails(false))
        dispatch(notify('Task details updated'))
        if (currentJob.downloads.length > 0) {
          updateDownloadStatus()
        }
      })
      .catch((e) => {
        dispatch(actionHubUpdatingTaskDetails(false))
        showNotification('Could not save changes, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const submitTaskLabelsBulkUpdate = (modalId) => {
    const selectedTasksToUpdate = selectedTasks.map(selectedTask => ({
      id: selectedTask._id,
      labels: tasks.find(i => i._id === selectedTask._id).labels.filter(label => label.value)
        .map(label => ({
          ...label,
          labelId: label.id,
          labelName: label.displayName,
        })),
    }))
    dispatch(actionHubFetchSelectedTasks(true))
    putLabelsBulkUpdate(actionSetId, 0, selectedTasksToUpdate)
      .then(({ data }) => {
        if (currentJob.downloads.length > 0) {
          updateDownloadStatus()
        }
        afterLabelsUpdated(data, true)
        dispatch(actionHubUpdateSelectedTasks(actionSetId, []))
        dispatch(modalHide(modalId))
      })
      .catch((e) => {
        dispatch(actionHubFetchSelectedTasks(false))
        labelUpdateErrorResponse(e)
      })
  }

  const submitTaskLabels = async () => {
    try {
      dispatch(submittingTaskLabels(actionSetId, taskId))

      const { data } = await putLabels(actionSetId, 0, taskId, task.labels)

      if (currentJob.downloads.length > 0) {
        await updateDownloadStatus()
      }

      dispatch(actionHubUpdatingTaskDetails(true))
      const { data: responseData } = await getTaskDetailsOnly(actionSetId, taskId, allowComments)
      dispatch(actionHubUpdateTaskDetails(actionSetId, taskId, responseData))
      dispatch(actionHubUpdatingTaskDetails(false))

      afterLabelsUpdated(data, false)
    } catch (e) {
      dispatch(actionHubFetchSelectedTasks(false))
      if (e.message === 'Failed to fetch task details') {
        showNotification('Could not load task details, please refresh the page and contact support if issues persist.', e, 'error')
      } else {
        labelUpdateErrorResponse(e)
      }
    } finally {
      dispatch(actionHubUpdatingTaskDetails(false))
    }
  }

  const setTaskActionState = async (previousTaskActionId, newTaskActionId) => {
    try {
      dispatch(submittedTaskActionState(actionSetId, [taskId], newTaskActionId))

      const isCommentReceived = newTaskActionId === 5
      const isCommentRequired = newTaskActionId === 4 || isCommentReceived
      dispatch(updateIsCommentRequired(taskId, isCommentRequired))
      dispatch(updateIsCommentReceived(taskId, isCommentReceived))

      const updatedData = {
        taskActionId: newTaskActionId,
        isCommentRequired,
        isCommentReceived,
      }
      await putTasksUpdate(actionSetId, [taskId], updatedData)
      updateGlobalFilters()
    } catch (e) {
      dispatch(submittedTaskActionState(actionSetId, [taskId], previousTaskActionId))
      const previousIsCommentRequired = previousTaskActionId === 4 || previousTaskActionId === 5
      const previousIsCommentReceived = previousTaskActionId === 5
      dispatch(updateIsCommentRequired(taskId, previousIsCommentRequired))
      dispatch(updateIsCommentReceived(taskId, previousIsCommentReceived))
      showNotification('Could not save changes, please refresh the page and contact support if issues persist.', e, 'error')
    }
  }

  const setTasksActionState = async (taskIdsToUpdate, newTaskActionId) => {
    try {
      dispatch(submittedTaskActionState(actionSetId, taskIdsToUpdate, newTaskActionId))

      const isCommentReceived = newTaskActionId === 5
      const isCommentRequired = newTaskActionId === 4 || isCommentReceived
      taskIdsToUpdate.forEach((id) => {
        dispatch(updateIsCommentRequired(id, isCommentRequired))
        dispatch(updateIsCommentReceived(id, isCommentReceived))
      })

      const updatedData = {
        taskActionId: newTaskActionId,
        isCommentRequired,
        isCommentReceived,
      }
      await putTasksUpdate(actionSetId, taskIdsToUpdate, updatedData)
      updateGlobalFilters()
    } catch (e) {
      taskIdsToUpdate.forEach((id) => {
        const previousTaskActionId = taskIdsToUpdate.find(i => i._id === id).taskActionId
        dispatch(submittedTaskActionState(actionSetId, [id], previousTaskActionId))
        const previousIsCommentRequired = previousTaskActionId === 4 || previousTaskActionId === 5
        const previousIsCommentReceived = previousTaskActionId === 5
        dispatch(updateIsCommentRequired(id, previousIsCommentRequired))
        dispatch(updateIsCommentReceived(id, previousIsCommentReceived))
      })
      showNotification('Could not save Action state, please refresh the page and contact support if issues persist.', e, 'error')
    }
  }

  const getAssignee = (assigneeType) => {
    switch (assigneeType) {
      case 'clientTeamId':
        return 'client'
      case 'reviewerId':
        return 'reviewer'
      default:
        return 'assignee'
    }
  }

  const setTaskAssignee = (userId, assigneeType) => {
    putTasksAssignee(actionSetId, [taskId], userId, assigneeType)
      .then(() => {
        dispatch(submittedTaskAssignee(actionSetId, [taskId], userId, assigneeType))
        updateGlobalFilters()
        showNotification(`Updated ${getAssignee(assigneeType)} for task`)
      })
      .catch((e) => {
        showNotification('Could not save changes, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const setTaskStatus = (statusId) => {
    putTasksStatus(actionSetId, [taskId], statusId)
      .then(() => {
        dispatch(submittedTaskStatus(actionSetId, [taskId], statusId))
        updateGlobalFilters()
        showNotification('Status updated')
      })
      .catch((e) => {
        showNotification('Could not save status, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const setTaskPriority = (priorityId) => {
    putTasksPriority(actionSetId, [taskId], priorityId)
      .then(() => {
        dispatch(submittedTaskPriority(actionSetId, [taskId], priorityId))
      })
      .catch((e) => {
        showNotification('Could not save priority, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const removeTaskComment = (commentId) => {
    deleteTaskComment(actionSetId, taskId, commentId)
      .then(({ data }) => {
        throw new Error(`To be implemented! ${data}`)
      })
  }

  const setTasksCommentStatus = async (selectedIds, commentStatusId) => {
    dispatch(submittedTaskCommentStatus(actionSetId, selectedIds, commentStatusId))

    await putTasksCommentStatus(actionSetId, selectedIds, commentStatusId)
      .catch((e) => {
        selectedIds.forEach((id) => {
          const currentTask = allTasks[id]
          const previousCommentStatusId = currentTask.commentStatusId
          dispatch(submittedTaskCommentStatus(actionSetId, [id], previousCommentStatusId))
        })
        showNotification('Could not save comment status, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const setTaskCommentStatus = async (commentStatusId) => {
    await setTasksCommentStatus([taskId], commentStatusId)
  }

  const submitTaskComment = async (comment, fileNames, commentType) => {
    dispatch(submittingTaskComment(actionSetId, taskId))

    try {
      const { data: responseData } = await postTaskComment(actionSetId, taskId, comment, fileNames, commentType)

      dispatch(actionHubFetchingCommentAuthors())
      try {
        const { data: authorData } = await getUserById(responseData.userId)
        dispatch(actionHubFetchedCommentAuthor(responseData.userId, authorData))
      } catch (e) {
        showNotification('Failed to fetch comment author', e, 'error')
      }

      dispatch(submittedTaskComment(actionSetId, taskId, responseData))

      if ((commentType === 1 || commentType === 2) && !isUserExternal && task.commentStatusId === 0) {
        setTaskCommentStatus(1)
      }

      return responseData
    } catch (e) {
      dispatch(submittingTaskCommentFailed(actionSetId, taskId))
      showNotification('Could not save comment. Please try again or contact support if the issue persists.', e, 'error')
      throw e
    }
  }

  const removeAssignedTask = async () => {
    await dispatch(actionHubRemoveTask(parseInt(taskId, 10)))
  }

  return {
    data: task,
    dataCount: task?.pagination?.totalItems,
    taskData,
    taskTitle,
    columns: mergedColumns,
    detailColumns: displayDetailColumns,
    meta,
    isFetched: !needsLoading && task._isFetched,
    goToTaskList,
    setLabelValue,
    submitTaskLabelsBulkUpdate,
    submitTaskLabels,
    validateLabel,
    setTaskActionState,
    setTasksActionState,
    setTaskAssignee,
    setTaskStatus,
    setTaskPriority,
    fetchNextPage,
    submitTaskComment,
    allowComments,
    allowAttachments,
    removeTaskComment,
    updateTaskDetails,
    setTaskCommentStatus,
    setTasksCommentStatus,
    removeAssignedTask,
    loadTaskData,
  }
}

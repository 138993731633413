import React from 'react'
import PropTypes from 'prop-types'

const IconSuccessOutline = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2077 5.94831L8.16683 11.9891L4.876 8.70748L3.5835 9.99998L8.16683 14.5833L15.5002 7.24998L14.2077 5.94831ZM10.0002 0.833313C4.94016 0.833313 0.833496 4.93998 0.833496 9.99998C0.833496 15.06 4.94016 19.1666 10.0002 19.1666C15.0602 19.1666 19.1668 15.06 19.1668 9.99998C19.1668 4.93998 15.0602 0.833313 10.0002 0.833313ZM10.0002 17.3333C5.9485 17.3333 2.66683 14.0516 2.66683 9.99998C2.66683 5.94831 5.9485 2.66665 10.0002 2.66665C14.0518 2.66665 17.3335 5.94831 17.3335 9.99998C17.3335 14.0516 14.0518 17.3333 10.0002 17.3333Z" fill="#26890D"/>
  </svg>
)

IconSuccessOutline.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

IconSuccessOutline.defaultProps = {
  width: 20,
  height: 20,
}

export default IconSuccessOutline

import React from 'react'
import PropTypes from 'prop-types'

const IconWarningOutline = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9998 3.94919L17.9023 15.875H4.09734L10.9998 3.94919ZM10.9998 0.291687L0.916504 17.7084H21.0832L10.9998 0.291687Z" fill="#DE6C05"/>
    <path d="M11.9165 13.125H10.0832V14.9584H11.9165V13.125Z" fill="#DE6C05"/>
    <path d="M11.9165 7.62502H10.0832V12.2084H11.9165V7.62502Z" fill="#DE6C05"/>
  </svg>
)

IconWarningOutline.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

IconWarningOutline.defaultProps = {
  width: 22,
  height: 18,
}

export default IconWarningOutline

import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Button } from '@deloitte/gel-library'
import styles from './AggregationBar.scss'
import TextSearchFilter from '../TextSearchFilter'

function AggregationBar({ aggregations, handleAddTaskClick, showAddTaskButton }) {
  const mainAggregation = aggregations.find(x => x.type === 'primary')

  if (!mainAggregation) {
    return <div>Loading...</div>
  }

  const showFilteredCount = mainAggregation.totalActions !== mainAggregation.filteredTasksCount && mainAggregation.filteredTasksCount > 0

  const description = showFilteredCount
    ? `${mainAggregation.description.replace('$VALUE$', mainAggregation.value)} (showing ${mainAggregation.filteredTasksCount})`
    : `${mainAggregation.description.replace('$VALUE$', mainAggregation.value)}`

  return (
    <div className={styles.base}>
      <ReactMarkdown source={description} />
      <div className={styles.contentContainer}>
        {showAddTaskButton && (
          <Button
            className={styles.addTaskBtn}
            mode="secondary"
            onClick={handleAddTaskClick}
          >
            ADD
          </Button>
        )}
        <TextSearchFilter />
      </div>
    </div>
  )
}

AggregationBar.propTypes = {
  aggregations: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
  })).isRequired,
  handleAddTaskClick: PropTypes.func.isRequired,
  showAddTaskButton: PropTypes.bool,
}

AggregationBar.defaultProps = {
  showAddTaskButton: false,
}

export default AggregationBar

import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'actions/app'
import { useCallback } from 'react'

export default function useApp() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const match = useSelector(state => state.match)

  // Wrapped in useCallback because often we'll want to use
  // this function inside useEffect calls and not trigger re-renders
  const showNotification = useCallback((message, error, type, timeout) => {
    if (error) {
      console.error(message, error)
    }

    // Only skip notifications for redirects (3xx)
    const statusCode = error?.response?.status
    const isRedirect = statusCode >= 300 && statusCode < 400
    if (!isRedirect) {
      dispatch(notify(message, type, timeout))
    }
  }, [dispatch])

  return { user, match, showNotification }
}
